.App {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
}

.row {
  background: #f8f9fa;
}

h2 {
  font-weight: bold !important;
}

hr {
  border: 1px solid !important;
  margin-bottom: 0px !important;
}

h4 {
  margin-top: 20px !important;
  font-size: 1.3em !important;
}

#blocks:hover #layer_b {
  transform: translate(10px, 10px);
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

#blocks:hover #layer_c {
  transform: translate(20px, 20px);
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
}

#blocks #layer_b,
#layer_c {
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
}

.subtext {
  font-size: 0.85em;
}

.form-check.form-check-inline {
  margin-right: 2px;
}
